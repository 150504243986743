import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Balkone from "../queries/balkone"
import ServiceAndProcedure from "../components/serviceAndProcedure"

const Index = () => {
  const { sliderImage } = useStaticQuery(
    graphql`
      query {
        sliderImage: file(relativePath: { eq: "slider/header_balkon.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )
  const image = getImage(sliderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout spacing="true">
      <Seo
        title="Balkone und Terrassen"
        description="Ein Balkon am Haus ist nicht nur ein Raumgewinn, sondern ein Blickfang und Ruheort an der frischen Luft."
      />
      <div className="flex w-full h-screen">
        <BackgroundImage
          {...bgImage}
          style={{ width: "100%" }}
          className="h-full w-full flex items-center"
        >
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-72">
            <h1 className="text-4xl md:text-5xl font-bold mb-1.5">
              Balkone & Terrassen
            </h1>
            <p className="max-w-4xl text-xl mt-1">
              Ein Balkon am Haus ist nicht nur ein Raumgewinn, sondern ein
              Blickfang und Ruheort an der frischen Luft.
            </p>
          </div>
        </BackgroundImage>
      </div>
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 bg-white">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center lg:order-last">
            <img
              src="/zeichnungen/balkon.svg"
              className="block h-56"
              alt="Technische Zeichnung eines Balkons"
            />
          </div>
          <div className="flex items-center">
            <p>
              Balkone und Terrassen werden aus feuerverzinktem und
              pulverbeschichtetem Stahl nach Maß gefertigt. Lassen Sie sich von
              uns einen modernen und zeitlosen Balkon planen, passend zu jedem
              Gebäudestil. Selbstverständlich bieten wir individuelle Lösungen
              für Neubauten sowie bestehende Häuser. Des Weiteren bieten wir
              eine breite Auswahl an Bodenbelägen, welche dem Stil des Gebäudes
              passend ausgewählt werden können.
            </p>
          </div>
        </div>
      </div>
      <ServiceAndProcedure />
      <div className="pb-16">
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 text-gray-800">
          Galerie
        </h2>
        <Balkone />
      </div>
    </Layout>
  )
}

export default Index
